/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * The possible sides of a diff.
 */
export enum DiffSide {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

/**
 * The possible states of a pull request review comment.
 */
export enum PullRequestReviewCommentState {
  PENDING = "PENDING",
  SUBMITTED = "SUBMITTED",
}

/**
 * The possible events to perform on a pull request review.
 */
export enum PullRequestReviewEvent {
  APPROVE = "APPROVE",
  COMMENT = "COMMENT",
  DISMISS = "DISMISS",
  REQUEST_CHANGES = "REQUEST_CHANGES",
}

/**
 * The possible states of a pull request review.
 */
export enum PullRequestReviewState {
  APPROVED = "APPROVED",
  CHANGES_REQUESTED = "CHANGES_REQUESTED",
  COMMENTED = "COMMENTED",
  DISMISSED = "DISMISSED",
  PENDING = "PENDING",
}

/**
 * The possible states of a pull request.
 */
export enum PullRequestState {
  CLOSED = "CLOSED",
  MERGED = "MERGED",
  OPEN = "OPEN",
}

/**
 * Autogenerated input type of AddPullRequestReviewComment
 */
export interface AddPullRequestReviewCommentInput {
  body: string;
  clientMutationId?: string | null;
  commitOID?: any | null;
  inReplyTo?: string | null;
  path?: string | null;
  position?: number | null;
  pullRequestId?: string | null;
  pullRequestReviewId?: string | null;
}

/**
 * Autogenerated input type of AddPullRequestReviewThread
 */
export interface AddPullRequestReviewThreadInput {
  body: string;
  clientMutationId?: string | null;
  line: number;
  path: string;
  pullRequestId?: string | null;
  pullRequestReviewId?: string | null;
  side?: DiffSide | null;
  startLine?: number | null;
  startSide?: DiffSide | null;
}

/**
 * Autogenerated input type of SubmitPullRequestReview
 */
export interface SubmitPullRequestReviewInput {
  body?: string | null;
  clientMutationId?: string | null;
  event: PullRequestReviewEvent;
  pullRequestId?: string | null;
  pullRequestReviewId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
